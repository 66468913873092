import {
    LovAccount,
    LovAccountData,
    LovAccountPeriod,
    LovAccountRule,
    LovAgent,
    LovApprovalMatrix,
    LovCashBankAccount,
    LovCeding,
    LovClient,
    LovCosting,
    LovDocument,
    LovEntity,
    LovFinReport,
    LovFixedAsset,
    LovLedger,
    LovLineOfBussines,
    LovObject,
    LovPeriodMaintenance,
    LovReinsurance,
    LovReport,
    LovRoles,
    LovTable,
    LovTableDefinition,
    LovTaxes,
    LovTemplates,
    LovTypeOfCover,
    LovTypeOfInfo,
    LovTypeOfInterest,
    LovTypeOfRisk,
    LovUsers
} from '../interfaces/lov.interface';
import { LovInvoice } from '../interfaces/invoice.interface';
import { LovBilling } from '../interfaces/billing.interface';
import { LovPolicy } from '../interfaces/policy.interface';
import { LovPayment } from '../interfaces/payment.interface';
import { LovReceipt } from '../interfaces/receipt.interface';
import { LovClaim } from '../interfaces/claim.interface';
import { LovVoucher } from '../interfaces/voucher.interface';
import { LovQuotation } from '../interfaces/quotation.interface';
import { LovRequest } from '../interfaces/request.interface';
import { LovProposal } from '../interfaces/proposal.interface';
import { LovTreaty } from '../interfaces/treaty.interface';
import { LovBatch } from '../interfaces/batch.interface';
import { LovApproval } from '../interfaces/approval.interface';
import { LovJournal } from '../interfaces/journal.interface';
import { LovPostingRule } from '../interfaces/posting.interface';

export const DEFAULT_LOV_REQUEST: LovRequest = {
    currencyList: [],
    agentList: [],
    requestStatusList: [],
    allList: [],
    cedingList: [],
    contractTypeList: [],
    insuredList: [],
    lobList: [],
    marketingStaffList: [],
    requestSourceList: [],
    requestTypeList: [],
    sourceOfBusinessList: [],
    tocList: [],
    requestGroupList: [],
    agentCommissionType: [],
}

export const DEFAULT_LOV_QUOTATION: LovQuotation = {
    quotationSlipList: [],
    currencyList: [],
    objectTypeList: [],
    occupationList: [],
    sectionList: [],
    toiList: [],
    torList: [],
    insuredList: [],
    policyList: [],
    allList: [],
    cedingList: [],
    lobList: [],
    reinsList: [],
    confirmationList: [],
    riRateClassList: [],
    riRateTypeList: [],
    statusList: [],
    tocList: []
}

export const DEFAULT_LOV_PROPOSAL: LovProposal = {
    allList: [],
    cedingList: [],
    lobList: [],
    currencyList: [],
    statusList: [],
    tocList: [],
    proposalSlipList: [],
    riRateClassList: [],
    riRateTypeList: [],
    confirmationList: [],
    costingSourceList: [],
}

export const DEFAULT_LOV_DOCUMENT: LovDocument = {
    allList: [],
    documentTypeSource: [],
}

export const DEFAULT_LOV_FIN_REPORT: LovFinReport = {
    allList: [],
    accountSource: [],
    columnOperatorSource: [],
    columnPeriodTypeSource: [],
    columnTypeSource: [],
    rowTypeSource: [],
    rowOperatorSource: [],
}

export const DEFAULT_LOV_LOB: LovLineOfBussines = {
    allList: [],
    lobGroupSource: [],
}

export const DEFAULT_LOV_TAXES: LovTaxes = {
    allList: [],
    taxTypeSource: [],
    statusSource: [],
    accountSource: [],
}

export const DEFAULT_LOV_TABLE_DEFINITION: LovTableDefinition = {
    allList: [],
    tableDefinitionStatusSource: [],
}

export const DEFAULT_LOV_ACCOUNT_DATA: LovAccountData = {
    allList: [],
    accountSource: [],
}

export const DEFAULT_LOV_TOR: LovTypeOfRisk = {
    allList: [],
    torGroupSource: [],
    torLobSource: [],
    torSectionSource: [],
}

export const DEFAULT_FIXED_ASSET: LovFixedAsset = {
    allList: [],
    assetCategorySource: [],
    assetClassSource: [],
    assetsGroupSource: [],
    assetsStatusSource: [],
    assetsTypeSource: [],
}

export const DEFAULT_LOV_TOINFO: LovTypeOfInfo = {
    allList: [],
    sourceTypeSource: [],
    interestObjectTypeSource: [],
}

export const DEFAULT_LOV_TOI: LovTypeOfInterest = {
    allList: [],
    toiSectionSource: [],
    toiLobSource: [],
}

export const DEFAULT_LOV_TOC: LovTypeOfCover = {
    allList: [],
    tocGroupSource: [],
    tocLobSource: [],
}

export const DEFAULT_LOV_ENTITY: LovEntity = {
    approvalDecisionList: [],
    documentStatusList: [],
    documentTypeList: [],
    entityTypeList: [],
    transCodeList: [],
    allList: []
}

export const DEFAULT_LOV_COSTING: LovCosting = {
    allList: [],
    costingSourceDoc: [],
    costingSourceQts: [],
    costingSource: [],
    riRateClassList: [],
    riRateTypeList: [],
    statusList: [],
    tocList: [],
    lobList: [],
    currencyList: [],
}

export const DEFAULT_LOV_REPORT: LovReport = {
    allList: [],
    reportEntitySource: [],
    reportOutputFormatSource: [],
    reportParamSource: [],
    reportParamValueSource: [],
    reportTypeSource: [],
    reportStatusSource: [],
}

export const DEFAULT_LOV_CLIENT: LovClient = {
    allList: [],
    businessLineSource: [],
    businessEntitySource: [],
    clientCategorySource: [],
    clientClassSource: [],
    clientGroupSource: [],
    clientStatusSource: [],
    clientTypeSource: [],
    clientGenderSource: [],
    clientMaritalStatusSource: [],
    clientNationalitySource: [],
    clientReligionSource: [],
    clientAddressTypeSource: [],
    clientBankSource: [],
    clientContactTypeSource: [],
    clientBankAccountTypeSource: [],
    clientProvinceSource: [],
    clientCountrySource: [],
    clientIDTypeSource: [],
}

export const DEFAULT_LOV_CEDING: LovCeding = {
    allList: [],
    cedingCategorySource: [],
    cedingClassSource: [],
    cedingGroupSource: [],
    cedingStatusSource: [],
    cedingTypeSource: [],
}

export const DEFAULT_LOV_REINSURANCE: LovReinsurance = {
    allList: [],
    reinsuranceCategorySource: [],
    reinsuranceClassSource: [],
    reinsuranceGroupSource: [],
    reinsuranceStatusSource: [],
    reinsuranceTypeSource: [],
}

export const DEFAULT_LOV_USERS: LovUsers = {
    allList: [],
    userDepartmentSource: [],
    userDivisionSource: [],
    userLinkTypeSource: [],
    userTypeSource: [],
    userGroupSource: [],
    userToleSource: [],
}

export const DEFAULT_LOV_TABLE: LovTable = {
    allList: [],
    currencySource: [],
}

export const DEFAULT_LOV_ACCOUNT_RULE: LovAccountRule = {
    allList: [],
    journalComponentSource: [],
    journalPositionSource: [],
    transCodeSource: [],
    transGroupSource: [],
    statusSource: [],
    accountListSource: [],
    accountDimensionList: [],
}

export const DEFAULT_LOV_POSTING_RULE: LovPostingRule = {
    allList: [],
    statusList: [],
    ruleTypeList: [],
    postingAccountTypeList: [],
    accountList: [],
    customerList: [],
    lobList: []
}


export const DEFAULT_LOV_ACCOUNT_PERIOD: LovAccountPeriod = {
    allList: [],
    periodStatusSource: [],
}

export const DEFAULT_LOV_APPROVAL_MATRIX: LovApprovalMatrix = {
    allList: [],
    approvalModeSource: [],
    approvalStatusSource: [],
    approvalTypeSource: [],
    approvalUserSource: [],
    entityTypeSource: [],
    transGroupSource: [],
}

export const DEFAULT_LOV_CASHBANK_ACCOUNT: LovCashBankAccount = {
    allList: [],
    cashBankAccountSource: [],
    cashBankSource: [],
    cashBankStatusSource: [],
    currencySource: [],
}

export const DEFAULT_LOV_AGENT: LovAgent = {
    allList: [],
    agentCategorySource: [],
    agentClassSource: [],
    agentGroupSource: [],
    agentStatusSource: [],
    agentTypeSource: [],
}

export const DEFAULT_LOV_ROLES: LovRoles = {
    allList: [],
    rolesAccessTypeSource: [],
    rolesAccessTableId: [],
    rolesSystemNameSource: [],
}

export const DEFAULT_LOV_TEMPLATES: LovTemplates = {
    allList: [],
    templateTypeSource: [],
    filedCodeSource: [],
}

export const DEFAULT_LOV_TREATY: LovTreaty = {
    allList: [],
    cedingList: [],
    currencyList: [],
    lobList: [],
    reinsList: [],
    reinstateCodeList: [],
    sectionList: [],
    tocList: [],
    treatyGroupList: [],
    treatyInfoList: [],
    statusList: [],
    treatyTypeList: [],
    masterContractList: [],
}

export const DEFAULT_LOV_BATCH: LovBatch = {
    batchStatusList: [],
    batchTypeList: [],
    jobList: [],
    allList: []
}

export const DEFAULT_LOV_JOURNAL: LovJournal = {
    accountList: [],
    allList: [],
    currencyList: [],
    journalSourceList: [],
    journalStatusList: [],
    transCodeList: [],
    accountDimensionList: [],
};

export const DEFAULT_LOV_ACCOUNT: LovAccount = {
    accountCategoryList: [],
    accountList: [],
    accountPositionList: [],
    accountTypeList: [],
    allList: []
}

export const DEFAULT_LOV_LEDGER: LovLedger = {
    accountList: [],
    transCodeList: [],
    allList: []
};

export const DEFAULT_LOV_PERIOD_MAINTENANCE: LovPeriodMaintenance = {
    allList: [],
    periodSource: []
}

export const DEFAULT_LOV_INVOICE: LovInvoice = {
    allList: [],
    itemList: [],
    taxCodeList: [],
    whtCodeList: [],
    accountList: [],
    currencyList: [],
    partyList: [],
    sourceDocList: [],
    sourceTypeList: [],
    invoiceStatusList: [],
    invoiceTypeList: [],
    invoiceTypeNewList: [],
    partyTypeList: [],
    termsList: [],
    accountDimensionList: [],
    itemTypeList: [],
}

export const DEFAULT_LOV_BILLING: LovBilling = {
    lobList: [],
    tocList: [],
    billingAmountSubTypeList: [],
    billingAmountTypeList: [],
    billingEventTypeList: [],
    partyList: [],
    sourceDocList: [],
    sourceTypeList: [],
    billingStatusList: [],
    billingTypeList: [],
    currencyList: [],
    partyTypeList: [],
    allList: []
}


export const DEFAULT_LOV_POLICY: LovPolicy = {
    allList: [],
    agentList: [],
    cedingList: [],
    currencyList: [],
    insuredList: [],
    insuredSourceList: [],
    contractSourceList: [],
    lobList: [],
    marketingStaffList: [],
    objectTypeList: [],
    policyTypeList: [],
    riskOccupationList: [],
    salesBranchList: [],
    salesChannelList: [],
    sectionList: [],
    sourceOfBusinessList: [],
    statusList: [],
    tocList: [],
    toiList: [],
    torList: [],
    cedingPolicySourceList: [],
    reinsSourceList: [],
    agentCommissionType: [],
}

export const DEFAULT_LOV_PAYMENT: LovPayment = {
    accountList: [],
    bankAccountList: [],
    cashBankList: [],
    allList: [],
    currencyList: [],
    partyList: [],
    partyTypeList: [],
    paymentTypeList: [],
    statusList: [],
    paymentMethodList: [],
    accountDimensionList: [],
}

export const DEFAULT_LOV_OBJECT: LovObject = {
    allList: [],
    objectParentSource: [],
    recordStatusSource: [],
    tableSource: [],
    objectGroupSource: [],
    objectStatusSource: [],
    objectTypeSource: [],
}

export const DEFAULT_LOV_RECEIPT: LovReceipt = {
    accountList: [],
    bankAccountList: [],
    cashBankList: [],
    allList: [],
    currencyList: [],
    partyList: [],
    partyTypeList: [],
    receiptTypeList: [],
    statusList: []
}

export const DEFAULT_LOV_CLAIM: LovClaim = {
    allList: [],
    claimTypeList: [],
    contractTypeList: [],
    currencyList: [],
    statusList: [],
    causeOfLostList: [],
    contractList: [],
    expenseTypeList: []
}

export const DEFAULT_LOV_VOUCHER: LovVoucher = {
    allList: [],
    taxCodeList: [],
    whtCodeList: [],
    accountList: [],
    currencyList: [],
    partyList: [],
    partyTypeList: [],
    sourceDocList: [],
    sourceTypeList: [],
    statusList: [],
    voucherTypeList: [],
    termsList: [],
}

export const DEFAULT_LOV_APPROVAL: LovApproval = {
    approverList: [],
    allList: []
}
